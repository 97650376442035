import React, { useState } from 'react'
import { useForm, ValidationError } from '@formspree/react';

const Feedback = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        category: '',
        feedback: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [state, handleSubmit] = useForm("mdoqpwlv");
    if (state.succeeded) {
        return <div className='container fs-2 my-5 '>Thanks for providing the feedback!</div>;
    }

    return (
        <div className='container mb-4'>
            <h2 className='text-center mb-5 mt-3'>We value your feedback! Share your thoughts with us.</h2>

            <form className='w-75 mx-auto fs-5' onSubmit={handleSubmit}>
                <div className='my-4'>
                    <label className='form-label' htmlFor="name">Your Name</label>
                    <input className='fs-5 px-2  form-control' type="text" value={formData.name} onChange={handleChange} id="name" name="name" required/>
                </div>
                <div className='my-4'>
                    <label className='form-label' for="email">Your Email</label>
                    <input className='fs-5 px-2 form-control' type="email" value={formData.email} onChange={handleChange} id="email" name="email" required/>
                </div>
                <div className='my-4'>
                    <label className='form-label' for="category">Feedback Category:</label>
                    <select className='fs-5 px-2 form-control' id="category" value={formData.category} onChange={handleChange} name="category">
                        <option value="">Select</option>
                        <option value="bug">Bug Report</option>
                        <option value="feature">Feature Request</option>
                        <option value="general">General Feedback</option>
                        <option value="account">Account Related</option>

                    </select>
                </div>
                <div className='my-4'>
                    <label className='form-label' for="feedback">Your Feedback:</label>
                    <textarea className='fs-5 px-2 form-control' id="feedback" value={formData.feedback} onChange={handleChange} name="feedback" rows="4" cols="50" required></textarea>
                </div>


                <button className='fs-5 btn btn-primary' type="submit">Submit Feedback</button> 

            </form>
        </div>
    )
}

export default Feedback
