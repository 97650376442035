import React from 'react'

const Aboutus = () => {
    return (
        <div className='container pt-4 w-100 fs-5 my-3'>
            <h3 className='text-center fw-bold'>
                About Us
            </h3> <br />

            <p className='fw-bold'>
                Welcome to ESquare - the ultimate destination for quiz enthusiasts seeking fun, challenge, and real rewards!
            </p>
            <p>
                ESquare was born from a passion for trivia and a desire to create a platform where players can engage in exciting quizzes while having the chance to win fantastic prizes. Our mission is to provide a captivating and rewarding experience that brings people together through friendly competition and shared knowledge.
            </p>
            <p>
                What sets ESquare apart is our commitment to delivering a seamless and enjoyable gaming experience. From carefully curated quiz topics to interactive gameplay features, every aspect of ESquare is designed to delight and engage our players.
            </p>
            <p>
                Here's what you can expect from ESquare: <br />

                <p>
                    <b>
                        Exciting Quizzes: </b> Explore a wide range of quiz categories, from general knowledge and pop culture to history, science, and more. We regularly update our quiz library to keep the challenges fresh and interesting. <br />
                </p>
                <p>
                    <b>
                        Real Prizes: </b> Compete for top scores on our leaderboard to win amazing prizes. Whether it's cash rewards, gift cards, or exclusive merchandise, there are plenty of incentives to test your knowledge and aim for the top spot. <br />
                </p>
                <p>
                    <b>
                        Fair and Secure Gameplay: </b> ESquare is committed to fairness and transparency. We ensure that all gameplay is conducted with integrity and that winners are determined based on merit. <br />
                </p>
                <p>
                    <b>
                        Community Engagement: </b> Join a vibrant community of players who share your passion for quizzes. Connect with friends, challenge rivals, and celebrate each other's achievements. <br />

                </p>
            </p>
            <p>
                At ESquare, we believe that learning can be both entertaining and rewarding. Whether you're a seasoned quizzer or a casual player looking for some excitement, ESquare is your go-to destination for immersive quiz experiences.
            </p>
            <p>
                Join us on this thrilling journey of knowledge and competition. Download ESquare today and start quizzing your way to victory!
            </p>
            <p>
                Let's quiz, win, and have fun together. Welcome to ESquare - where every question counts!

            </p>

        </div>
    )
}

export default Aboutus
