import React from 'react'

const TopGrad_Privacy = () => {
  return (
    <>
          <div  >
          <h1>Privacy Policy</h1>
        
        <div style={{padding: '10px'}}>
              <p>Welcome to TopGrad! This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our educational resources application, including PDFs, notes, practice sets, practice questions, and more. By using our application, you agree to the collection and use of information in accordance with this policy.</p>

              <h2>Collection of Information</h2>
              <p>We collect various types of information to provide and improve our services:</p>
              <ul>
                  <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and other details you provide during registration or while using our services.</li>
                  <li><strong>Usage Data:</strong> We collect information about how you use our app, including pages viewed, features used, and the time spent on the app.</li>
                  <li><strong>Device Information:</strong> We may collect information about the device you use to access our app, including your IP address, browser type, and operating system.</li>
              </ul>

              <h2>Representation and Warranties</h2>
              <p>By using our app, you represent and warrant that you are legally eligible to enter into a binding contract and that you will comply with all applicable laws and regulations. You also warrant that any information you provide is accurate, complete, and up-to-date.</p>

              <h2>Purpose and Use of Information</h2>
              <p>The information we collect is used to:</p>
              <ul>
                  <li>Operate and maintain our app and services.</li>
                  <li>Personalize your experience by tailoring the content and features to your preferences.</li>
                  <li>Communicate with you, including sending updates, notifications, and promotional materials.</li>
                  <li>Analyze usage patterns to improve our app and develop new features.</li>
                  <li>Comply with legal obligations and protect our rights and interests.</li>
              </ul>

              <h2>Sharing of Information</h2>
              <p>We may share your information with third parties under the following circumstances:</p>
              <ul>
                  <li><strong>Service Providers:</strong> We may share information with third-party service providers who assist us in operating our app, conducting our business, or servicing you.</li>
                  <li><strong>Legal Requirements:</strong> We may disclose your information if required by law, or to protect our rights, property, or safety.</li>
                  <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</li>
              </ul>

              <h2>Data Retention</h2>
              <p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. Once your information is no longer needed, we will securely delete or anonymize it.</p>

              <h2>Cookies and Third-Party Application Links</h2>
              <p>We use cookies and similar technologies to enhance your experience on our app. Cookies help us understand how you interact with our app, personalize your experience, and improve our services. You can manage your cookie preferences through your browser settings.</p>
              <p>Our app may contain links to third-party websites or applications. We are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before providing any personal information.</p>

              <h2>Protection of Information</h2>
              <p>We implement a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, secure access controls, and regular security audits. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

              <h2>Option to Opt-Out and Withdrawal of Information</h2>
              <p>You have the right to opt out of receiving promotional communications from us at any time. You may also request the deletion or withdrawal of your personal information by contacting us directly. Please note that withdrawing your information may affect your ability to use certain features of our app.</p>

              <h2>Grievance Redressal</h2>
              <p>If you have any concerns or complaints regarding the handling of your personal information, you can contact our grievance officer at support@topgrad.com. We will address your concerns in a timely and effective manner.</p>

              <h2>Updation of Policy</h2>
              <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, along with the date of the latest revision. Your continued use of our app after any changes signifies your acceptance of the updated policy.</p>

              <h2>Governing Law and Jurisdiction</h2>
              <p>This Privacy Policy is governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising from or relating to this policy will be subject to the exclusive jurisdiction of the courts in [Your Country/State].</p>

              <h2>Use of Application by Children</h2>
              <p>Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information. Parents or guardians who believe that their child has provided us with personal information should contact us immediately.</p>
          </div>
          
          </div>
        
        </>

  )
}

export default TopGrad_Privacy
