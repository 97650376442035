import React from "react";

const Privacy_policy = () => {
  return (
    <div className="container py-5" style={{textAlign: 'justify'}}>
      <h3 className="fw-bold">PRIVACY POLICY</h3>
      <h6 className="text-end ">Last updated June 25, 2024</h6> <br />
      <div>
        This privacy notice for Netrom Services India Pvt. Ltd. (doing business
        as ESquare) ("we," "us," or "our"), describes how and why we might
        collect, store, use, and/or share ("process") your information when you
        use our services ("Services"), such as when you: Visit our website, or
        any website of ours that links to this privacy notice Download and use
        our mobile application (ESquare), or any other application of ours that
        links to this privacy notice Engage with us in other related ways,
        including any sales, marketing, or events Questions or concerns? Reading
        this privacy notice will help you understand your privacy rights and
        choices. If you do not agree with our policies and practices, please do
        not use our Services. If you still have any questions or concerns,
        please contact us at esquare@netrom.co.in.
      </div>
      <br />
      <h4>SUMMARY OF KEY POINTS</h4> <br />
      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>
      <p>
        What personal information do we process? <br /> When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with us and the Services, the choices you make, and the
        products and features you use. Learn more about personal information you
        disclose to us. <br />
        Do we process any sensitive personal information? <br /> We do not
        process sensitive personal information. <br />
        Do we receive any information from third parties? <br /> We do not
        receive any information from third parties. <br />
        How do we process your information? <br /> We process your information
        to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about how we process your information.
        <br />
        In what situations and with which parties do we share personal
        information? <br /> We may share information in specific situations and
        with specific third parties. Learn more about when and with whom we
        share your personal information.
        <br />
      </p>
      <p>
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Learn more about your privacy rights.
        <br />
        How do you exercise your rights? The easiest way to exercise your rights
        is by submitting a data subject access request, or by contacting us. We
        will consider and act upon any request in accordance with applicable
        data protection laws.
        <br />
      </p>
      <div>
        Want to learn more about what we do with any information we collect?
        Review the privacy notice in full. <br /> <br />
        <ol>
          <h5>TABLE OF CONTENTS</h5>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
        </ol>
      </div>
      <br />
      {/* correctly organised */}
      <div>
        <h5>1. WHAT INFORMATION DO WE COLLECT?</h5> <br />
        Personal information you disclose to us <br />
        In Short: We collect personal information that you provide to us. <br />
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us. <br />
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following: <br />
        <ul>
          <li>names</li>
          <li>phone numbers</li>
          <li>email addresses</li>
          <li>date of birth</li>
          <li>gender</li>
          <li>currently preparing for</li>
        </ul>
        <p>Sensitive Information. We do not process sensitive information.</p>
        <p>
          Payment Data. We may collect data necessary to process your payment if
          you make purchases, such as your payment instrument number, and the
          security code associated with your payment instrument. All payment
          data is stored by Razorpay. You may find their privacy
          notice link(s) here:{" "}
          <a href="https://razorpay.com/privacy/">
            https://razorpay.com/privacy/
          </a>{" "}
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
      </div>
      <br />
      <div>
        <h5>2. HOW DO WE PROCESS YOUR INFORMATION?</h5> <br />
        <p>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including: To
          facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </p>
        <p>
          To request feedback. <br /> We may process your information when
          necessary to request feedback and to contact you about your use of our
          Services.
        </p>
        <ul>
          <li>
            To deliver targeted advertising to you. We may process your
            information to develop and display personalized content and
            advertising tailored to your interests, location, and more.
          </li>
          <li>
            To administer prize draws and competitions. We may process your
            information to administer prize draws and competitions.
          </li>
          <li>
            To evaluate and improve our Services, products, marketing, and your
            experience. We may process your information when we believe it is
            necessary to identify usage trends, determine the effectiveness of
            our promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li>
            To identify usage trends. We may process information about how you
            use our Services to better understand how they are being used so we
            can improve them.
          </li>
          <li>
            To comply with our legal obligations. We may process your
            information to comply with our legal obligations, respond to legal
            requests, and exercise, establish, or defend our legal rights.
          </li>
        </ul>
      </div>
      <br />
      <div>
        <h5>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
        <br />
        <p>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </p>
        <p>
          We may need to share your personal information in the following
          situations: <br />
          Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
      </div>
      <br />
      <div>
        <h5>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
        <br />
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>

        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>
      <br />
      <div>
        <h5>5. DO WE COLLECT INFORMATION FROM MINORS?</h5> <br />
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at esquare@netrom.co.in.
        </p>
      </div>
      <br />
      <div>
        <h5>6. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <br />
        <p>
          In Short: You may review, change, or terminate your account at any
          time.
        </p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <b>Account Information</b>
        <br />
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <ul>
            <li>Contact us using the contact information provided.</li>
            <li>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </li>
          </ul>
        </p>
        If you have questions or comments about your privacy rights, you may
        email us at esquare@netrom.co.in.
      </div>
      <br />
      <div>
        <h5>7. CONTROLS FOR DO-NOT-TRACK FEATURES</h5> <br />
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
      </div>
      <br />
      <div>
        <h5>8. DO WE MAKE UPDATES TO THIS NOTICE?</h5> <br />
        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
      </div>
      <br />
      <div>
        <h5>9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5> <br />
        <p>
          If you have questions or comments about this notice, you may email us
          at esquare@netrom.co.in or contact us by post at: <br />
          Netrom Services India Pvt. Ltd. <br />
          H/O-Om Prakash Singh <br />
          Krishnapuri, Bagm <br />
          Hajipur, Bihar 844101 <br />
          India <br />
        </p>
      </div>
      <br />
      <div>
        <h5>
          10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h5>{" "}
        <br />
        <p>
          You have the right to request access to the personal information we
          collect from you, change that information, or delete it. To request to
          review, update, or delete your personal information, please fill out
          and submit a data subject access request.
        </p>
      </div>

      <br />
      <div>
        <h5>
          11. WALLET FUNCTIONALITY
        </h5>{" "}
        <br />
        <ul>
          <li>
            <b>Adding Money to Your Wallet: </b>
            <p>When you add money to your wallet, we may collect transaction details such as the amount added, payment method used, and transaction ID. This information is necessary to facilitate the addition of funds to your wallet.</p>
          </li>
          <li>
            <b>Using Wallet for Payments: </b>
            <p>When you use your wallet to pay entry fees for quizzes or other services, we may collect information about the transactions including the amount spent, services purchased, and transaction ID.</p>
          </li>
          <li>
            <b>Earning Money by Winning Quizzes: </b>
            <p> If you earn money by winning quizzes, we may collect information about your winnings, including the amount won, quiz details, and transaction ID.</p>
          </li>
          <li>
            <b>Withdrawing Wallet Balance: </b>
            <p>To facilitate the withdrawal of your wallet balance to your bank account, we may collect additional information such as your bank account details (account number, bank name, IFSC code) and transaction details (amount withdrawn, transaction ID).</p>
          </li>
        </ul>
      </div>
      <br />
      <div>
        <h5>
          12.  HOW DO WE PROCESS WALLET INFORMATION?
        </h5>{" "}
        <br />
        <p>
        We process your wallet information for various purposes, including:
        </p>
        <ul>
        <li>
            <b>To manage your wallet balance. </b>
            <p>We keep track of the funds in your wallet and facilitate the addition, use, and withdrawal of funds.</p>
          </li>
          <li>
            <b>To facilitate transactions. </b>
            <p>We process transaction details to ensure the accurate addition, use, and withdrawal of funds.</p>
          </li>
          <li>
            <b>To comply with legal obligations.  </b>
            <p> We may process your wallet information to comply with financial regulations and legal obligations, such as anti-money laundering laws.</p>
          </li>
        </ul>
      </div>
      <br />
      <div>
        <h5>
          13. HOW LONG DO WE KEEP WALLET INFORMATION?
        </h5>{" "}
        <br />
        <p>
        We will only keep your wallet information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as financial regulations). When we have no ongoing legitimate business need to process your wallet information, we will either delete or anonymize such information, or, if this is not possible (for example, because your wallet information has been stored in backup archives), then we will securely store your wallet information and isolate it from any further processing until deletion is possible.
        </p>
      </div>

      <br />
      <br />
      <div>If you have any questions or concerns about our privacy practices, please contact us at esquare@netrom.co.in.</div>
    </div>
  );
};

export default Privacy_policy;
