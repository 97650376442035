import React from 'react'

const Terms_of_service = () => {
    return (
        <div className='container my-4' >
            <h3 className='fw-bold'>
                ESquare Terms of Service (India)
            </h3> <br />
            <h4>
                Welcome to ESquare!
            </h4>
            <div>
                <p>
                    These Terms and Conditions ("Terms") govern your access and use of the ESquare mobile application ("App"). By downloading and using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, then you may not access or use the App.
                </p>
            </div>
            <br />
            <div>
                <p>
                    <h5>
                        1. Eligibility
                    </h5> <br />
                    <ul>
                    <li>
                    You must be at least 18 years old and have the legal capacity to enter into a binding contract to use the App. <br />
                    </li>
                    <li>
                    You are solely responsible for ensuring that your access to and use of the App complies with all applicable laws and regulations in India, including any laws regarding gambling or contests.
                    </li>
                    </ul>
                </p>
            </div>
            <br />
            <div>
                <h5>
                    2. User Accounts
                </h5>
                <br />
                <p>
                    You may be required to create an account to access certain features of the App. You are responsible for maintaining the confidentiality of your account information, including your login credentials. <br />

                    You are also responsible for all activities that occur under your account.

                    <br />You can add money to your wallet from your bank account. The wallet balance can be used to pay the entry fee for quizzes.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    3. Content
                </h5>
                <br />
                <p>
                    The App contains content, including quizzes, questions, answers, explanations, leaderboards, and cash prizes (collectively, "Content"). The Content is protected by intellectual property laws, including copyright and trademark laws.
                    <br />
                    You may not copy, modify, distribute, sell, lease, or reverse engineer any part of the Content without our prior written consent.

                </p>
            </div>
            <br />
            <div>
                <h5>4. Wallet Functionality</h5>
                <br />
                <ul>
                    <li><b>Adding Money to Your Wallet: </b> You can add money to your wallet through a secure payment method. Transaction details such as the amount added, payment method used, and transaction ID will be collected.</li>
                    
                    <li><b>Using Wallet for Payments:  </b> You can use your wallet balance to pay entry fees for quizzes. Information about the transactions including the amount spent, services purchased, and transaction ID will be collected.</li>
                    
                    <li><b>Earning Money by Winning Quizzes: </b> If you earn money by winning quizzes, we will collect information about your winnings, including the amount won, quiz details, and transaction ID.</li>
                    
                    <li><b>Withdrawing Wallet Balance:  </b> To withdraw your wallet balance to your bank account, you must provide your bank account details (account number, bank name, IFSC code) and transaction details (amount withdrawn, transaction ID).</li>
                </ul>
            </div>
            <br />
            <div>
                <h5>
                    5. Quizzes and Entry Fees
                </h5>
                <br />
                <p>
                    The App offers various quizzes with different difficulty levels, categories, and potential prize pools.
                    <br />
                    You will be required to pay a small entry fee to participate in each quiz. This fee will be deducted from your wallet balance.
                    <br />
                    The entry fee structure will be clearly displayed within the App and may vary depending on the quiz and potential prize pool.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    6. Cash Prizes and Leaderboard
                </h5>
                <br />
                <p>
                    Users who perform well in quizzes will be ranked on a leaderboard.
                    <br />
                    Cash prizes will be added to your wallet balance based on your position on the leaderboard and the specific prize pool for each quiz.
                    <br />
                    We reserve the right to modify the prize pool allocation, leaderboard criteria, or withdrawal methods for cash prizes at any time.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    7. Cash Prize Disbursement
                </h5>
                <br />
                <p>
                    To be eligible to receive a cash prize, you must:
                    <br />
                    Be a legal resident of India and at least 18 years old.
                    <br />
                    Comply with all verification procedures, which may include providing valid government identification and tax information.
                    <br />
                    Not have violated any of these Terms.
                    <br />
                    Cash prizes will be disbursed to your wallet balance after verification and within a reasonable timeframe (details will be provided within the App). You can then withdraw the amount from your wallet to your bank account.
                    <br />
                    You are responsible for any taxes or fees associated with receiving and withdrawing a cash prize (details will be provided within the App).
                </p>
            </div>
            <br />
            <div>
                <h5>
                    8. General Rules For Distribution Of Winnigs
                </h5>
                <br />
                <p>
                    <ul>
                        <li>
                            The User accumulating the highest points at the end of a match shall be eligible to win a pre-designated winning disbursed out of the Prize Money as stated on the relevant Quiz page.
                        </li>
                        <li>
                            If there is a tie, the winnings will be distributed on the basis of less time taken by user.
                        </li>
                        <li>
                            We reserve the right to vary or modify the winnings at Our discretion before the Contest Deadline.
                        </li>
                        <li>
                            You agree that any winnings in the nature of prizes or rewards awarded by Us shall be governed by the terms and conditions of such specific promotional events.
                        </li>
                        <li>
                                We reserve the right to disqualify any Winner and prevent them from withdrawing their winnings or receiving any prize if:
                            <ul>
                                <li>
                                    any of the document or information submitted by You are incorrect, misleading, false, fabricated, incomplete or illegible; or
                                </li>
                                <li>
                                    You do not abide by these Terms.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>
            </div>

            <br />

            <div>
                <h5>
                    9. Payment Gateway
                </h5>
                <br />
                <p>
                    All payments for adding money to your wallet will be processed through a secure third-party payment gateway.
                    <br />
                    You are responsible for any fees associated with using the payment gateway.
                    <br />
                    We are not responsible for any errors or issues related to the processing of payments. However, we will make reasonable efforts to assist you in resolving any such issues.
                    <br />
                </p>
            </div>
            <br />
            <div>
                <h5>
                    10. Cancellation and Refund Policy
                </h5>
                <br />
                <div>
                    <h6><b>Refunds</b></h6>
                    We understand that unforeseen circumstances may arise. You are eligible for a full refund in the following situations:
                    <ul>
                        <li>
                        <b>Technical Issues:</b> If the quiz is not held at the specified time due to a technical issue on our end, or if you experience server issues preventing you from taking the quiz, a full refund will be issued.
                        </li>

                        <li>
                        <b>Payment Error:</b> If your payment successfully reaches us but you are unable to access the quiz due to a technical error, a full refund will be processed.
                        </li>
                    </ul>
                    
                    <p>**Refund Processing Time:** Once we receive your refund request and verify your eligibility, we will process your refund within **5-7 business days**. You will be notified via email once your refund is complete.</p>

                    <h6><b>Cancellations</b></h6>
                    <p>We enforce a strict no-cancellation policy after you have paid the entry fee for a quiz. This ensures a fair and balanced environment for all participants.</p>
                
                    <h6><b>How to Request a Refund</b></h6>
                    <p>If you believe you qualify for a refund under the conditions outlined above, please contact us within 7 days of the scheduled quiz at esquare@netrom.co.in. In your email, please include:</p>

                    <ul>
                        <li>Your full name</li>
                        <li>The email address associated with your account</li>
                        <li>The name of the quiz you were registered for</li>
                        <li>A brief explanation of why you are requesting a refund</li>
                    </ul>

                    <p>We will review your request and respond within 7 business days.</p>

                    <p><b>Please Note: </b>Refunds will be issued to the original payment method used during registration.</p>
                </div>
            </div>
            <br />
            <div>
                <h5>
                    11. User Conduct
                </h5>
                <br />
                <p>
                    You agree to use the App in a lawful and respectful manner.
                    <br />
                    <ul>
                        You are prohibited from:
                        <li>
                            Cheating or attempting to cheat in quizzes (including using unauthorized assistance, technical glitches, or multiple accounts).
                        </li>
                        <li>
                            Using the App to harm or harass other users.
                        </li>
                        <li>
                            Uploading any inappropriate or offensive content.
                        </li>
                        <li>
                            Exploiting any bugs or glitches in the App.
                        </li>
                        <li>
                            Engaging in any activity that disrupts the fair and orderly operation of the App.
                        </li>
                    </ul>
                </p>
            </div>
            <br />
            <div>
                <h5>
                    12. Termination
                </h5>
                <br />
                <p>
                    We may terminate your access to the App at any time, for any reason, without notice. This may include violations of these Terms, suspicious activity, or technical difficulties.
                    <br />
                    We may also take legal action against you for any violation of these Terms.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    13. Disclaimer
                </h5>
                <br />
                <p>
                    The App is provided "as is" and without any warranties of any kind, express or implied.
                    <br />
                    We do not warrant that the App will be uninterrupted, error-free, or virus-free.
                    <br />
                    The Content provided in the quizzes may not be entirely accurate or up-to-date.
                    <br />
                    We will not be liable for any damages arising from your use of the App or the Content.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    14. Limitation of Liability
                </h5>
                <br />
                <p>
                    To the maximum extent permitted by law, our liability for any damages arising from your use of the App is limited to the amount you paid for entry fees.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    15. Governing Law
                </h5>
                <br />
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of India.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    16. Dispute Resolution
                </h5>
                <br />
                <p>
                    Any dispute arising out of or relating to these Terms will be subject to the exclusive jurisdiction of the courts located in India.
                </p>
            </div>
            <br />
            <div>
                <h5>
                    Contact Us
                </h5>
                <p>
                    Netrom Services India Pvt. Ltd. <br />
                    H/O-Om Prakash Singh <br />
                    Krishnapuri, Bagm <br />
                    Hajipur, Bihar 844101 <br />
                    India <br />
                    Phone: +916123189771 <br />
                    Email: esquare@netrom.co.in <br />
                </p>
            </div>
        </div>
    )
}

export default Terms_of_service
