import React from 'react'
import { Link } from 'react-router-dom'



const Nav = () => {
    return (
        <div className='pb-3' style={{borderBottom: '1px solid black'}}>
            <div className='d-flex container nav-container'>
                <div className='flex-fill h-100 '>
                    {/* Logo Image */}
                    <Link to = "/">
                        <img className='h-100' src="../esquare_logo.png" alt="" />
                    </Link>
                </div>
                <div className='h-100'>
                    <img  className='logo-2 h-100' src="esquare_logo_2.png" alt="" />
                </div>
                <div className='flex-fill'>

                </div>
            </div>
            
        </div>
    )
}

export default Nav
