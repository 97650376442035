import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {

    return (
        <div className='bg-body-secondary'>
            <div>
                <div className='container d-flex justify-content-lg-around mt-5 flex-wrap '>
                    <div className='d-flex flex-column mb-5'>

                        <h5 className='mb-3'>
                            Company
                        </h5>

                        <div className='d-flex flex-column '>
                            <Link to="/aboutus" className='links'  >About Us</Link>
                            <Link to="/terms" className='links'  >Terms of Service</Link>
                            <Link to="/privacy" className='links'  >Privacy policy</Link>
                            <Link to="/feedback" className='links'  >Share your Feedback</Link>
                        </div>

                    </div>
                    
                    <div className='d-flex d-none flex-column mb-5'>

                        <h5 className='mb-4'>
                            Stay Connected with us
                        </h5>

                        <div className='d-flex flex-wrap'>

                            {/* Facebook link */}
                            <a className='mx-1 icon' href="https://www.facebook.com/">
                                <img src="facebook.png" className='w-100' alt="facebook" />
                            </a>

                            {/* Instagram link */}

                            <a className='mx-1 icon ' href="https://www.instagram.com/">
                            <img src="instagram.png" className='w-100' alt="facebook" />
                            </a>

                            {/* Twitter link */}

                            <a className='mx-1 icon' href="https://twitter.com/">
                            <img src="twitter.png" className='w-100' alt="facebook" />
                            </a>

                            {/* Youtube link */}

                            <a className='mx-1 icon' href="https://www.youtube.com/">
                            <img src="youtube.png" className='w-100' alt="facebook" />
                            </a>

                            {/* Linkedin link */}

                            <a className='mx-1 icon' href="https://www.linkedin.com/">
                            <img src="linkedin.png" className='w-100' alt="facebook" />
                            </a>

                        </div>

                    </div>
                
                    <div className='d-flex flex-column'>

                        <h5 className='mb-4'>
                            Contact Us
                        </h5>

                        <div>
                            Phone :- +916123189771
                        </div>
                        <div>
                            Email :- esquare@netrom.co.in
                        </div>

                    </div>
                </div>
            </div>

            {/* Copyright part */}

            <div className='text-center'>
                <hr />
                <p className='pb-2 m-0'>
                    © {new Date().getFullYear()} Netrom Services India Private Limited. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer