import React from 'react'

const DeleteAccount = () => {
  return (
    <div className='container mt-3 '>
        <h1>Deactivate Account</h1>
        <p>We're sorry to see you go! Deactivating your account will temporarily disable your access to the platform. </p>
        <p>**Important:** Deactivation is not the same as deletion. If you'd like to permanently delete your account and data, please contact us at devmind000@gmail.com</p>
        <form>
            <button type="submit" disabled>Deactivate Account</button>
        </form>
    </div>
  )
}

export default DeleteAccount
