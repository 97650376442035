import React from 'react'


const Main = () => {
    return (
        <div className=''>
            {/* Intro Section */}
            <div className='intro-container' >
                <div className='container py-5 px-3'>
                    <h1 className='text-white mb-4 intro-heading'>Level Up Your Knowledge. <br /> Win Big!</h1>
                    <p className='text-white app-desc' style={{}}>Join ESquare, the ultimate quiz app for learning, competition, and cash prizes.</p>

                    <a href="https://play.google.com/store/apps/details?id=com.victous.quiz" target='_blank'><button className='fs-5 btn btn-warning mt-3 mx-auto download-btn'>Download the app</button></a>
                </div>
            </div>

            {/* Quiz description */}
            <div className='row container  ' style={{ margin: '4rem auto' }}>
                <h1 className='text-center mb-5 fw-bold '>The ESquare Arena: Choose Your Quiz Battle</h1>
                <div className='card col-lg-3' >
                    <h1 className='text-center'>Daily Quiz</h1>
                    <div className='card-body '>
                        <p><b>Sharpen your Brain Every Day:</b> Keep your mind active and test your knowledge with a quick and engaging daily quiz. </p>
                            <p><b>Bite-Sized Challenges:</b> Answer a set of questions on a variety of topics, perfect for a short mental break or a quick learning boost.</p>
                            <p><b>Daily Dose of Learning:</b> Learn something new every day and track your progress as you answer daily quizzes.</p>
                    </div>
                </div>

                <div className='card col-lg-3' >
                    <h1 className='text-center'>Weekly Quiz</h1>
                    <div className='card-body '>
                        <p><b>Dive Deeper Each Week:</b>Take on a more comprehensive quiz every week, covering a specific theme or subject in greater detail.</p>
                            <p><b>Test Your Knowledge Accumulation:</b> Challenge yourself with a broader range of questions compared to the daily quiz..</p>
                            <p><b>Compete for Bigger Prizes:</b> Weekly quizzes often offer higher potential rewards compared to daily quizzes.</p>
                    </div>
                </div>

                <div className='card col-lg-3' >
                    <h1 className='text-center'>Monthly Quiz</h1>
                    <div className='card-body '>
                        <p><b>The Ultimate Challenge:</b> Put your knowledge to the ultimate test with a comprehensive monthly quiz spanning various topics.</p>
                        <p><b>Rise to the Top:</b> Compete against other players for the highest score and the chance to win the biggest prizes in ESquare.</p>
                        <p><b>Deep Dive Learning:</b> Monthly quizzes encourage in-depth learning and knowledge retention over a longer period.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Main
