import './App.css';
import Aboutus from './components/Aboutus';
import Feedback from './components/Feedback';
import Main from './components/Main';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import Privacy_policy from './components/Privacy_policy';
import Terms_of_service from './components/Terms_of_service';
import Nav from './components/Nav';
import Footer from './components/Footer';
import DeleteAccount from './components/DeleteAccount';
import TopGrad_Privacy from './components/TopGrad_Privacy';
import TopGrad_DeleteAccount from './components/TopGrad_DeleteAccount';

function App() {
  return (

    <div className='d-flex flex-column' style={{minHeight: "100vh"}}>
      <Router>
        <Nav />
        <Switch>
          <Route exact path='/aboutus' element={<Aboutus />} />
          <Route exact path='/feedback' element={<Feedback />} />
          <Route exact path='/terms' element={<Terms_of_service />} />
          <Route exact path='/privacy' element={<Privacy_policy />} />
          <Route exact path='/privacy-topgrad' element={<TopGrad_Privacy />} />
          <Route exact path='/account-deletion' element={<DeleteAccount />} />
          <Route exact path='/account-deletion-topgrad' element={<TopGrad_DeleteAccount />} />
          <Route path="/*" element={<Main />} />
        </Switch>
        <div className='flex-fill'></div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
